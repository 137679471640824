<template>
    <div id="container">
        <div id="BufferAnalysisMap">
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";

import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import GroupLayer from "@arcgis/core/layers/GroupLayer";

import Legend from "@arcgis/core/widgets/Legend";
import Expand from "@arcgis/core/widgets/Expand";
import Slider from "@arcgis/core/widgets/Slider";
import Search from "@arcgis/core/widgets/Search";
import LayerList from "@arcgis/core/widgets/LayerList";
import Circle from "@arcgis/core/geometry/Circle";

import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";

import create_cumulative_burden_layer from "@/map_layers/cumulative_burden";
import create_buffer_analysis_layer from "@/map_layers/buffer_analysis";
import create_unique_facility_layer from "@/map_layers/unique_facility";
import create_HAP_layer from "@/map_layers/HAP";
import create_CAP_layer from "@/map_layers/CAP";
import create_GHG_layer from "@/map_layers/GHG";


export default {
    data() {
        return {
            sliders: {
                "1 Mile Radius": {
                    "totpop_2020census": [0, 100],
                    "rpl_eji": [0, 100],
                    "rpl_ebm": [0, 100],
                    "rpl_svm": [0, 100],
                    "rpl_hvm": [0, 100],
                },
                "3 Mile Radius": {
                    "totpop_2020census": [0, 100],
                    "rpl_eji": [0, 100],
                    "rpl_ebm": [0, 100],
                    "rpl_svm": [0, 100],
                    "rpl_hvm": [0, 100],
                },
                "Cumulative Burden": {
                    "e_totpp": [0, 100],
                    "rpl_eji": [0, 100],
                    "rpl_ebm": [0, 100],
                    "rpl_svm": [0, 100],
                    "rpl_hvm": [0, 100],
                },
                HAP: {},
                CAP: {},
                GHG: {},
            },
            slider_names: {
                "totpop_2020census": "Population",
                "e_totpp":  "Population",
                "rpl_eji":  "Environmental Justice Index Rank 2022",
                "rpl_ebm":  "Environmental Burden Percentile",
                "rpl_svm":  "Social Vulnerability Percentile",
                "rpl_hvm":  "Health Vulnerability Percentile",
                "emissions": "Emissions",
            },

            slider_stats: {
                "totpop_2020census": [],
                "e_totpp":  [],
                "rpl_eji":  [],
                "rpl_ebm": [
                    "Ozone",
                    "Particle Matter",
                    "Diesel Particulate Matter",
                    "Air Toxics Cancer Risk",
                ],
                "rpl_svm": [
                    "Population of Color",
                    "Low-Income",
                    "Adults without a High School Diploma",
                    "Unemployment",
                    "Renters",
                    "Housing Burdened Low-Income Households",
                    "Lack of Health Insurance",
                    "Age 65 and Older",
                    "Age 17 and Younger",
                    "Civilian with a Disability",
                    "Linguistic Isolation",
                ],
                "rpl_hvm": [
                    "High Blood Pressure",
                    "Adults with Asthma",
                    "Cancer",
                    "Diabetes",
                    "Not Good Mental Health",
                ],
                emissions: []
            },

            map_view: null,
        }
    },

    computed: {
        ...mapGetters([
            "chosen_BUFFER_ANALYSIS_data",
            "chosen_BUFFER_ANALYSIS_data_population_max",
            "chosen_CDC_EJI_data_population_max",
            "chosen_max_emissions",
            "chosen_state",
            "chosen_tract_map",
            "chosen_emission",
            "chosen_unique_facilities",
        ]),
    },
    methods: {

        create_map() {
            if (this.map_view  != null) {
                this.map_view.container = null;
            }

            this.state = this.chosen_tract_map;

            this.sliders.e_totpop = [0, this.chosen_BUFFER_ANALYSIS_data_population_max];

            // HAP
            let hap_layers = [];
            Object.entries(this.chosen_emission("HAP")).map(([year, data]) => {
                const year_max = this.chosen_max_emissions["HAP"][year];
                hap_layers.push(create_HAP_layer(year, data, year_max));
                this.sliders.HAP[year] = { "emissions": [0, year_max] };
            });
            const hap_group_layer = new GroupLayer({
                title: "HAP",
                visible: true,
                visibilityMode: "exclusive",
                layers: hap_layers
            });

            // CAP
            let cap_layers = [];
            Object.entries(this.chosen_emission("CAP")).map(([year, data]) => {
                const year_max = this.chosen_max_emissions["CAP"][year];
                cap_layers.push(create_CAP_layer(year, data, year_max))
                this.sliders.CAP[year] = { "emissions": [0, year_max] };
            });
            const cap_group_layer = new GroupLayer({
                title: "CAP",
                visible: true,
                visibilityMode: "exclusive",
                layers: cap_layers
            });

            // GHG
            let ghg_layers = [];
            Object.entries(this.chosen_emission("GHG")).map(([year, data]) => {
                const year_max = this.chosen_max_emissions["GHG"][year];
                ghg_layers.push(create_GHG_layer(year, data, year_max))
                this.sliders.GHG[year] = { "emissions": [0, year_max] };
            });
            const ghg_group_layer = new GroupLayer({
                title: "GHG",
                visible: true,
                visibilityMode: "exclusive",
                layers: ghg_layers
            });

            // Buffer Analysis
            let feature_layer_1_layer = create_buffer_analysis_layer("1 Mile Radius", this.chosen_BUFFER_ANALYSIS_data("1 miles"));
            let feature_layer_3_layer = create_buffer_analysis_layer("3 Mile Radius", this.chosen_BUFFER_ANALYSIS_data("3 miles"));
            const buffer_radius_group_layer = new GroupLayer({
                title: "Buffer Analysis",
                visible: true,
                visibilityMode: "exclusive",
                layers: [feature_layer_1_layer, feature_layer_3_layer]
            })

            // Cumulative Burden
            let cumulative_burden_eji_layer = create_cumulative_burden_layer(this.chosen_tract_map);
            let unique_facilities_layer = create_unique_facility_layer(this.chosen_unique_facilities);
            const cumulative_burden_group_layer = new GroupLayer({
                title: "Cumulative Burden",
                visible: true,
                listMode: "hide-children",
                layers: [cumulative_burden_eji_layer, unique_facilities_layer]
            })

            // Top layer
            const all_layers = new GroupLayer({
                title: "All layers",
                visibilityMode: "exclusive",
                layers: [
                    buffer_radius_group_layer,
                    cumulative_burden_group_layer,
                    cap_group_layer,
                    hap_group_layer,
                    ghg_group_layer,
                ]
            });

            const map = new Map({
                basemap: "osm-light-gray",
                visibilityMode: "exclusive",
                layers: [
                    // NOTE: the graphics layer is dynamically injected here
                    all_layers
                ]
            });
            const view = new MapView({
                map: map,
                zoom: 7.5,
                container: "BufferAnalysisMap"
            });
            this.map_view = view;

            reactiveUtils.watch(
                () => [view.map.allLayers.map((layer) => layer.visible)],
                () => {
                    const parent_layer = view.map.allLayers.filter((layer) => layer.type === "group" && !layer.title.includes("All") && layer.visible).pop();
                    let layer = parent_layer.layers.filter((layer) => layer.visible).pop();

                    if (parent_layer.title === cumulative_burden_group_layer.title) {
                        // NOTE: The cumulative burden group layer has 2 visible child layer so we have to make sure that the correct one is chosen
                        layer = parent_layer.layers.filter((layer) => layer.title === cumulative_burden_eji_layer.title).pop();
                    }


                    view.whenLayerView(layer).then((layer_view) => {
                        // find expand
                        const div = view.ui.find("slider-expand");
                        view.ui.remove(div);


                        // create new sliders for the visible view
                        let info_div = document.createElement("div");
                        info_div.setAttribute("id", "infoDiv");
                        info_div.setAttribute("class", "esri-widget");

                        view.ui.add(
                            new Expand({
                                id: "slider-expand",
                                view: view,
                                content: info_div,
                                expandIcon: "sliders-horizontal",
                            }),
                            "top-left"
                        );


                        let section = parent_layer.title;
                        if (section === "Buffer Analysis") {
                            section = layer.title;
                        }
                        // console.log(parent_layer.title, layer.title, section);

                        let all_sliders = this.sliders[section];
                        if (section === "GHG" || section === "CAP" || section === "HAP") {
                            const year = parseInt(layer.title);
                            all_sliders = this.sliders[section][year];
                        }

                        // populate sliders
                        let index = 0;
                        for (const parameter in all_sliders) {
                            if (index > 0) {
                                info_div.appendChild(document.createElement("br"));
                            }
                            let param_div = document.createElement("div");

                            let text = document.createElement("p");
                            text.innerText = this.slider_names[parameter];
                            text.setAttribute("class", "text-left font-weight-bold")
                            param_div.appendChild(text);

                            let div = document.createElement("div");
                            div.setAttribute("id", "slider");
                            param_div.appendChild(div);

                            // stats under each slider
                            let stat_div = document.createElement("div");
                            stat_div.setAttribute("class", "text-left");
                            for (let i = 0; i < this.slider_stats[parameter].length; i++) {
                                let stat_text = document.createElement("p");
                                stat_text.innerText = this.slider_stats[parameter][i];
                                stat_text.setAttribute("class", "pl-3 mb-0")
                                stat_div.appendChild(stat_text);
                            }
                            if (this.slider_stats[parameter].length > 0) {
                                param_div.appendChild(stat_div);
                            }

                            info_div.appendChild(param_div);


                            // get the corresponding max population range (the rest are percentiles)
                            let max = 100;
                            if (parameter == "totpop_2020census" || parameter == "e_totpp") {
                                if (section == "cumulative_burden") {
                                    max = this.chosen_CDC_EJI_data_population_max;
                                }
                                else {
                                    max = this.chosen_BUFFER_ANALYSIS_data_population_max(
                                        layer.title.includes("1") ? "1 miles" : "3 miles"
                                    );
                                }
                                all_sliders[parameter] = [0, max];
                            }

                            const slider = new Slider({
                                min: 0,
                                max: max,
                                values: all_sliders[parameter],
                                container: div,
                                visibleElements: {
                                    labels: true,
                                    rangeLabels: true
                                },
                                
                                precision: 0,

                                labelFormatFunction: (value) => {
                                    if(value >= 1000000){
                                        return (value / 1000000).toPrecision(3) + "m"
                                    }
                                    if(value >= 100000){
                                        return (value / 1000).toPrecision(3) + "k"
                                    }
                                    if(value >= 1000){
                                        return (value / 1000).toPrecision(2) + "k"
                                    }
                                    return value.toFixed(0);
                                }
                            });

                            slider.on(["thumb-change", "thumb-drag"], (event) => {
                                if (event.state !== "stop") return;
                                
                                all_sliders[parameter][event.index] = event.value;

                                // compose all slider ranges into a singel statement
                                let all_slider_ranges = [];
                                for (const p in all_sliders) {
                                    all_slider_ranges.push(
                                        p + " >= " + all_sliders[p][0] + " AND " + p + " <= " + all_sliders[p][1]
                                    );
                                }
                                layer_view.filter = {
                                    where: all_slider_ranges.join(" AND ")
                                };
                            });

                            index++;
                        }
                    });
                }
            )

            view.when(() => {
                // center view on geojson tract features
                feature_layer_1_layer.when(() => {
                    view.goTo(feature_layer_1_layer.fullExtent);
                });

                const layer_list = new LayerList({
                    title: "All maps",
                    view: view,
                    listItemCreatedFunction: async function(event) {
                        let item = event.item;

                        // Wait for the layer to load and the item title to become available
                        await item.layer.when();
                        if (item.title === "All layers") {
                            item.open = true;
                        }
                    }
                });
                view.ui.add(
                    new Expand({
                        view: view,
                        content:  layer_list,
                        expandIcon: "list-bullet",
                    }),
                    "top-right"
                );
                view.ui.add(
                    new Expand({
                        view: view,
                        content: new Search ({
                            view: view,
                        }),
                        expandIcon: "magnifying-glass",
                    }),
                    "top-left"
                );
            });

            // add the mile radius ring to the selected facility
            reactiveUtils.watch(
                () => view.popup?.selectedFeature,
                () => {
                    reactiveUtils.watch(
                        () => view.popup?.selectedFeature,
                        (selectedFeature) => {
                            if (selectedFeature == null) {
                                return;
                            }
                            if ("cluster_count" in selectedFeature.attributes) {
                                // ignore cluster points, and remove radius ring if visible
                                map.removeAll();
                                map.add(all_layers);

                                return;
                            }
                            if (!("buffer_radius" in selectedFeature.attributes)) {
                                // ignore feature that aren't buffer radius (like "search" points)
                                return;
                            }

                            let circle = new Circle({
                                center: [selectedFeature.geometry.longitude, selectedFeature.geometry.latitude],
                                geodesic: true,
                                radius: selectedFeature.attributes.buffer_radius === "1 miles" ? 1 : 3,
                                radiusUnit: "miles"
                            });
                            let graphics_layer = new GraphicsLayer({
                                listMode: "hide",
                                graphics: [{
                                    geometry: circle,
                                    symbol: {
                                        type: "simple-fill",
                                        style: "none",
                                        color: "red",
                                        outline: {
                                            width: 1,
                                            color: "black"
                                        }
                                    }
                                }]
                            });
                            map.removeAll();
                            map.add(graphics_layer);
                            map.add(all_layers);
                        },
                        { once: true }
                    );
                }
            );

            // remove the mile radius ring
            reactiveUtils.when(
                () => !view.popup?.visible,
                () => {
                    map.removeAll();
                    map.add(all_layers);
                }
            );

            let legend = new Legend({
                view: view,
            });
            view.ui.add(legend, "bottom-right");
        }
    },
    watch: {
        chosen_state() {
            this.create_map();
        }
    },
    mounted () {
        this.create_map();
    }
};
</script>

<style>
@import "https://js.arcgis.com/4.28/@arcgis/core/assets/esri/themes/light/main.css";

/*
Style colors (dark to light)

orange
#F76820
#F6911E
#F7AD4B
#FFCE6B

blue
#214C6F
#3A7493
#7BABBE
#A9CCDA

green
#275158
#02A79E
#96D8CB
#C3F2EB

red
#C4272D
#F04B3B
#F57B5D
#F9AB8D

black
#252728
#6E6F6E
#696969
#C3C2C6
*/

#container {
    display: flex;
    flex-direction: row;
}

#BufferAnalysisMap {
    height: 850px;
    width: 100%;
    text-align: left;
}

pre {
    text-align: left;
    white-space: pre-line;
}

/* Layer list */
.esri-layer-list.esri-widget.esri-widget--panel {
   text-align: left;
}
calcite-icon.esri-layer-list__status-indicator {
   display: none;
}

/* Legend */
.esri-component.esri-legend.esri-widget.esri-widget--panel {
   text-align: left;
   width: 210px;
}

/* Slider Filter Panel */
#infoDiv {
    background-color: white;
    padding: 10px;
    width: 300px;
    min-height: 100px;
    max-height: 600px;
    overflow-y: scroll;
    margin: 5px;
    font-size: 12px;
    display: block;
}
/* Slider */
.esri-slider__segment-1 {
    background: #f7911e;
}
.esri-slider__thumb {
    border: none; 
    /* 2px solid #f7911e; */
    background-color: #f7911e;
    width: 8px;
    height: 8px;
    top: -3px;
    left: -3px;
}
.esri-slider__anchor {
   font-size: 10px;
   color: #6e6f6e;
}
div#slider {
   font-size: 10px;
   color: #6e6f6e;
   margin: 20px 0 10px 0;
}

/* Cluster popup */
.open-container, .selection-container {
    display: none;
}
</style>
