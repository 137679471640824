import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export default function create_CAP_layer(title, data, max_value) {

    return new FeatureLayer({
        title: title,
        source: JSON.parse(JSON.stringify(data)),
        objectIdField: "id",
        fields: [
            { name: "id", alias: "id", type: "oid" },
            { name: "year", alias: "year", type: "integer" },
            { name: "pgm_sys_id", alias: "pgm_sys_id", type: "string" },
            { name: "dataset", alias: "dataset", type: "string" },
            { name: "keep", alias: "keep", type: "integer" },
            { name: "registry_id", alias: "registry_id", type: "string" },
            { name: "facility_name", alias: "facility_name", type: "string" },
            { name: "city", alias: "city", type: "string" },
            { name: "state", alias: "state", type: "string" },
            { name: "zip_code", alias: "zip_code", type: "integer" },
            { name: "address", alias: "address", type: "string" },
            { name: "county", alias: "county", type: "string" },
            { name: "latitude", alias: "latitude", type: "double" },
            { name: "longitude", alias: "longitude", type: "double" },
            { name: "naics_code", alias: "naics_code", type: "integer" },
            { name: "pollutant", alias: "pollutant", type: "string" },
            { name: "emissions", alias: "emissions", type: "double" },
            { name: "unit_of_measure", alias: "unit_of_measure", type: "string" },
            { name: "pollutant_type", alias: "pollutant_type", type: "string" },
            { name: "breakdown_table", alias: "breakdown_table", type: "string" },
        ],
        popupTemplate: {
            title: "{facility_name}",
            content: [{
                type: "fields",
                fieldInfos: [
                    { label: "Id", fieldName: "id" },
                    { label: "Year", fieldName: "year" },
                    { label: "PGM SYS Id", fieldName: "pgm_sys_id" },
                    { label: "Dataset", fieldName: "dataset" },
                    { label: "Registry Id", fieldName: "registry_id" },
                    { label: "Facility Name", fieldName: "facility_name" },
                    { label: "City", fieldName: "city" },
                    { label: "State", fieldName: "state" },
                    { label: "Zip Code", fieldName: "zip_code" },
                    { label: "Address", fieldName: "address" },
                    { label: "County", fieldName: "county" },
                    { label: "Latitude", fieldName: "latitude" },
                    { label: "Longitude", fieldName: "longitude" },
                    { label: "Naics code", fieldName: "naics_code" },
                    { label: "Pollutant", fieldName: "pollutant" },
                    { label: "Emissions", fieldName: "emissions" },
                    { label: "Unit of Measure", fieldName: "unit_of_measure" },
                    { label: "Pollutant Type", fieldName: "pollutant_type" },
                    { label: "Breakdown", fieldName: "breakdown_table" },
                ]
            }]
        },

        renderer: {
            type: "simple",
            symbol: {
                type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
                color: "gray",
                outline: {
                    color: "black",
                    width: 1
                }
            },

            visualVariables: [{
                type: "color",
                field: "emissions",
                stops: [
                    {
                        value: max_value * .2,
                        color: "#10A79E",
                        label: "0% - 19%"
                    },{
                        value: max_value * .4,
                        color: "#6BD8CB",
                        label: "20% - 39%"
                    },{
                        value: max_value * .6,
                        color: "#F6AD57",
                        label: "40% - 59%"
                    },{
                        value: max_value * .8,
                        color: "#EF4D40",
                        label: "60% - 79%"
                    },{
                        value: max_value,
                        color: "#C32A31",
                        label: "80% - 100%"
                    }
                ],
            }]
        }
    });
};
