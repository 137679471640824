import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export default function create_buffer_analysis_layer(title, radius_data) {
    const cluster_config = {
        type: "cluster",
        clusterRadius: "100px",
        clusterMinSize: "24px",
        clusterMaxSize: "60px",
        color: "#000000",
        popupTemplate: {
            title: "Cluster summary",
            content: "This cluster represents {cluster_count} facilities.",
            fieldInfos: [{
                fieldName: "cluster_count",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            }]
        },
        labelingInfo: [{
            deconflictionStrategy: "none",
            labelExpressionInfo: {
                expression: "Text($feature.cluster_count, '#,###')"
            },
            symbol: {
                type: "text",
                font: {
                    weight: "bold",
                    family: "Noto Sans",
                    size: "12px"
                }
            },
            labelPlacement: "center-center",
        }]
    };

    return new FeatureLayer({
        title: title,
        source: JSON.parse(JSON.stringify(radius_data)),
        objectIdField: "id",
        featureReduction: cluster_config,
        fields: [
            { name: "id", alias: "id", type: "oid" },
            { name: "rpl_eji", alias: "EJI", type: "double" },
            { name: "buffer_radius", alias: "buffer_radius", type: "string" },
            { name: "facility_name", alias: "facility_name", type: "string" },
            { name: "address", alias: "address", type: "string" },
            { name: "city", alias: "city", type: "string" },
            { name: "county", alias: "county", type: "string" },
            { name: "state", alias: "state", type: "string" },
            { name: "totpop_2020census", alias: "totpop_2020census", type: "double" },
            { name: "epl_ozone", alias: "epl_ozone", type: "double" },
            { name: "epl_pm", alias: "epl_pm", type: "double" },
            { name: "epl_dslpm", alias: "epl_dslpm", type: "double" },
            { name: "epl_totcr", alias: "epl_totcr", type: "double" },
            { name: "epl_minrty", alias: "epl_minrty", type: "double" },
            { name: "epl_pov200", alias: "epl_pov200", type: "double" },
            { name: "epl_nohsdp", alias: "epl_nohsdp", type: "double" },
            { name: "epl_unemp", alias: "epl_unemp", type: "double" },
            { name: "epl_renter", alias: "epl_renter", type: "double" },
            { name: "epl_houbdn", alias: "epl_houbdn", type: "double" },
            { name: "epl_uninsur", alias: "epl_uninsur", type: "double" },
            { name: "epl_age65", alias: "epl_age65", type: "double" },
            { name: "epl_age17", alias: "epl_age17", type: "double" },
            { name: "epl_disabl", alias: "epl_disabl", type: "double" },
            { name: "epl_limeng", alias: "epl_limeng", type: "double" },
            { name: "epl_bphigh", alias: "epl_bphigh", type: "double" },
            { name: "epl_asthma", alias: "epl_asthma", type: "double" },
            { name: "epl_cancer", alias: "epl_cancer", type: "double" },
            { name: "epl_diabetes", alias: "epl_diabetes", type: "double" },
            { name: "epl_mhlth", alias: "epl_mhlth", type: "double" },
            { name: "rpl_eji", alias: "rpl_eji", type: "double" },
            { name: "rpl_ebm", alias: "rpl_ebm", type: "double" },
            { name: "rpl_svm", alias: "rpl_svm", type: "double" },
            { name: "rpl_hvm", alias: "rpl_hvm", type: "double" },

            { name: "naics_code", alias: "naics_code", type: "integer" },
            { name: "industry_type", alias: "industry_type", type: "string" },

            { name: "hisp", alias: "hisp", type: "double" },
            { name: "nonhisp_asian", alias: "nonhisp_asian", type: "double" },
            { name: "nonhisp_black", alias: "nonhisp_black", type: "double" },
            { name: "nonhisp_hawaiian_pacif", alias: "nonhisp_hawaiian_pacif", type: "double" },
            { name: "nonhisp_native", alias: "nonhisp_native", type: "double" },
            { name: "nonhisp_white", alias: "nonhisp_white", type: "double" },

            { name: "latitude", alias: "latitude", type: "double" },
            { name: "longitude", alias: "longitude", type: "double" },
        ],
        popupTemplate: {
            title: "{facility_name}",
            content: [{
                type: "fields",
                fieldInfos: [
                    { label: "Buffer Radius", fieldName: "buffer_radius"},
                    { label: "Facility Name", fieldName: "facility_name"},
                    { label: "Address", fieldName: "address"},
                    { label: "City", fieldName: "city"},
                    { label: "County", fieldName: "county" },
                    { label: "State", fieldName: "state" },
                    { label: "Latitude", fieldName: "latitude"},
                    { label: "Longitude", fieldName: "longitude"},
                    { label: "Population", fieldName: "totpop_2020census" },

                    { label: "Environmental Justice Index", fieldName: "rpl_eji" },

                    { label: "Environmental Burden Percentile", fieldName: "rpl_ebm" },
                    { label: "Ozone", fieldName: "epl_ozone"},
                    { label: "Particle Matter", fieldName: "epl_pm" },
                    { label: "Diesel Particulate Matter", fieldName: "epl_dslpm" },
                    { label: "Air Toxics Cancer Risk", fieldName: "epl_totcr" },

                    { label: "Social Vulnerability Percentile", fieldName: "rpl_svm" },
                    { label: "Population of Color", fieldName: "epl_minrty"},
                    { label: "Low-Income", fieldName: "epl_pov200"},
                    { label: "Adults without a High School Diploma", fieldName: "epl_nohsdp"},
                    { label: "Unemployment", fieldName: "epl_unemp"},
                    { label: "Renters", fieldName: "epl_renter"},
                    { label: "Housing Burdened Low-Income Households", fieldName: "epl_houbdn"},
                    { label: "Lack of Health Insurance", fieldName: "epl_uninsur"},
                    { label: "Age 65 and Older", fieldName: "epl_age65"},
                    { label: "Age 17 and Younger", fieldName: "epl_age17"},
                    { label: "Civilian with a Disability", fieldName: "epl_disabl"},
                    { label: "Linguistic Isolation", fieldName: "epl_limeng"},

                    { label: "Health Vulnerability", fieldName: "rpl_hvm" },
                    { label: "High Blood Pressure", fieldName: "epl_bphigh"},
                    { label: "Adults with Asthma", fieldName: "epl_asthma"},
                    { label: "Cancer", fieldName: "epl_cancer"},
                    { label: "Diabetes", fieldName: "epl_diabetes"},
                    { label: "Not Good Mental Health", fieldName: "epl_mhlth"},

                    { label: "NAICS Code", fieldName: "naics_code" },
                    { label: "Industry Type", fieldName: "industry_type" },

                    { label: "Hispanic or Latino", fieldName: "hisp" },
                    { label: "Asian", fieldName: "nonhisp_asian" },
                    { label: "Black or African American", fieldName: "nonhisp_black" },
                    { label: "Native Hawaiian or Other Pacific Islander", fieldName: "nonhisp_hawaiian_pacif" },
                    { label: "American Indian or Alaska Native", fieldName: "nonhisp_nativ" },
                    { label: "White", fieldName: "nonhisp_white" },
                ]
            }]
        },

        renderer: {
            type: "simple",
            symbol: {
                type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
                color: "gray",
                outline: {
                    color: "black",
                    width: 1
                }
            },

            visualVariables: [{
                    type: "color",
                    field: "rpl_eji",
                    stops: [
                        {
                            value: 25,
                            color: "#EEEEEE",
                            label: "0% - 24%"
                        },{
                            value: 50,
                            color: "#CFCFCF",
                            label: "25% - 49%"
                        },{
                            value: 75,
                            color: "#B0B0B0",
                            label: "50% - 74%"
                        },{
                            value: 100,
                            color: "#949494",
                            label: "75% - 100%"
                        }
                    ],
                }
            ]
        }
    });
};
