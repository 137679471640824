import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex';
import store from './store.js'

import { BootstrapVue } from 'bootstrap-vue'

import config from "@arcgis/core/config";
config.apiKey = "AAPK8823d863534545cba307267bf77b125f5wA5BCk57pCSRp7_juQ1Wp-NDgHvBiY3Z4PRzBb3xwUPUvb1iXXnJ5qT1A_CRKfu";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuex);

new Vue({
    render: h => h(App),
    store: store
}).$mount('#app');
