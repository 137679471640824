import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";

export default function create_cumulative_burden_layer(tract_data) {
    const blob = new Blob([JSON.stringify(tract_data)], {
        type: "application/json",
    });

    // URL reference to the blob
    const url = URL.createObjectURL(blob);

    return new GeoJSONLayer({
        url: url,
        title: "Cumulative Burden",
        visible: true,
        popupTemplate: {
            title: "{locatin}",
            content: [{
                type: "fields",
                fieldInfos: [
                    { label: "GEOID", fieldName: "geoid" },
                    { label: "AFF GEOID", fieldName: "affgeod" },
                    { label: "State FP", fieldName: "statefp" },
                    { label: "County FP", fieldName: "contyfp" },
                    { label: "County", fieldName: "county" },
                    { label: "State Abbreviation", fieldName: "stt_bbr" },
                    { label: "State", fieldName: "stt_dsc" },
                    { label: "Location", fieldName: "locatin" },

                    { label: "Population", fieldName: "e_totpp" },

                    { label: "Environmental Justice Index", fieldName: "rpl_eji" },

                    { label: "Environmental Burden Percentile", fieldName: "rpl_ebm" },
                    { label: "Ozone", fieldName: "epl_ozn" },
                    { label: "Particle Matter", fieldName: "epl_pm" },
                    { label: "Diesel Particulate Matter", fieldName: "epl_dsl" },
                    { label: "Air Toxics Cancer Risk", fieldName: "epl_ttc" },

                    { label: "Social Vulnerability Percentile", fieldName: "rpl_svm" },
                    { label: "Population of Color", fieldName: "epl_mnr" },
                    { label: "Low-Income", fieldName: "epl_200" },
                    { label: "Adults without a High School Diploma", fieldName: "epl_nhs" },
                    { label: "Unemployment", fieldName: "epl_nmp" },
                    { label: "Renters", fieldName: "epl_rnt" },
                    { label: "Housing Burdened Low-Income Households", fieldName: "epl_hbd" },
                    { label: "Lack of Health Insurance", fieldName: "epl_nns" },
                    { label: "Age 65 and Older", fieldName: "epl_g65" },
                    { label: "Age 17 and Younger", fieldName: "epl_g17" },
                    { label: "Civilian with a Disability", fieldName: "epl_dsb" },
                    { label: "Linguistic Isolation", fieldName: "epl_lmn" },

                    { label: "Health Vulnerability", fieldName: "rpl_hvm" },
                    { label: "High Blood Pressure", fieldName: "epl_bph" },
                    { label: "Adults with Asthma", fieldName: "epl_sth" },
                    { label: "Cancer", fieldName: "epl_cnc" },
                    { label: "Diabetes", fieldName: "epl_dbt" },
                    { label: "Not Good Mental Health", fieldName: "epl_mhl" },

                    { label: "Hispanic or Latino", fieldName: "hisp_p" },
                    { label: "Asian", fieldName: "asian_p" },
                    { label: "Black or African American", fieldName: "black_p" },
                    { label: "Native Hawaiian or Other Pacific Islander", fieldName: "hw_pcf" },
                    { label: "American Indian or Alaska Native", fieldName: "nativ_p" },
                    { label: "White", fieldName: "white_p" },
                ],
            }]
        },
        renderer: {
            type: "class-breaks",
            field: "rpl_eji",
            legendOptions: {
                title: "Environmental Justice Index Rank (2020)",
            },
            defaultSymbol: {
                type: "simple-fill",
                color: "gray",
                style: "backward-diagonal",
                outline: {
                    color: "gray",
                    width: 0.5,
                },
            },
            defaultLabel: "no data",

            classBreakInfos: [
                {
                    minValue: 0,
                    maxValue: 24.9999,
                    symbol: {
                        type: "simple-fill",
                        color: "#EEEEEE",
                        style: "solid",
                        outline: {
                            color: "gray",
                            width: 0.5,
                        },
                    },
                    label: "0% - 24%",
                },
                {
                    minValue: 25,
                    maxValue: 49.9999,
                    symbol: {
                        type: "simple-fill",
                        color: "#CFCFCF",
                        style: "solid",
                        outline: {
                            color: "gray",
                            width: 0.5,
                        },
                    },
                    label: "25% - 49%",
                },
                {
                    minValue: 50,
                    maxValue: 74.999,
                    symbol: {
                        type: "simple-fill",
                        color: "#B0B0B0",
                        style: "solid",
                        outline: {
                            color: "gray",
                            width: 0.5,
                        },
                    },

                    label: "50% - 74%",
                },
                {
                    minValue: 75,
                    maxValue: 100,
                    symbol: {
                        type: "simple-fill",
                        color: "#949494",
                        style: "solid",
                        outline: {
                            color: "gray",
                            width: 0.5,
                        },
                    },
                    label: "75% - 100%",
                },
            ],
        },
    });
};
