import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export default function create_unique_facility_layer(data) {

    return new FeatureLayer({
        title: "Unique facilities",
        source: JSON.parse(JSON.stringify(data)),
        objectIdField: "id",
        fields: [
            { name: "id", alias: "id", type: "oid" },
            { name: "keep", alias: "keep", type: "integer" },
            { name: "registry_id", alias: "registry_id", type: "string" },
            { name: "facility_name", alias: "facility_name", type: "string" },
            { name: "city", alias: "city", type: "string" },
            { name: "state", alias: "state", type: "string" },
            { name: "county", alias: "county", type: "string" },
            { name: "latitude", alias: "latitude", type: "double" },
            { name: "longitude", alias: "longitude", type: "double" },
            { name: "naics_code", alias: "naics_code", type: "integer" },
            { name: "naics_code_short", alias: "naics_code_short", type: "integer" },
            { name: "industry_type", alias: "industry_type", type: "string" },
        ],
        popupTemplate: {
            title: "{facility_name}",
            content: [{
                type: "fields",
                fieldInfos: [
                    { label: "Id", fieldName: "id" },
                    { label: "Registry Id", fieldName: "registry_id" },
                    { label: "Facility Name", fieldName: "facility_name" },
                    { label: "City", fieldName: "city" },
                    { label: "State", fieldName: "state" },
                    { label: "County", fieldName: "county" },
                    { label: "Latitude", fieldName: "latitude" },
                    { label: "Longitude", fieldName: "longitude" },
                    { label: "Naics code", fieldName: "naics_code" },
                    { label: "Industry Type", fieldName: "industry_type" },
                ]
            }]
        },

        renderer: {
            type: "unique-value",
            field: "naics_code_short",
            uniqueValueInfos: [
                {
                    "value": 2111,
                    "label": "Oil and Gas Extraction",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#F76820",
                        "size": 7
                    }
                },
                {
                    "value": 3241,
                    "label": "Petroleum and Coal Products Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#214C6F",
                        "size": 7
                    }
                },
                {
                    "value": 3251,
                    "label": "Basic Chemical Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#275158",
                        "size": 7
                    }
                },
                {
                    "value": 3252,
                    "label": "Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#F6911E",
                        "size": 7
                    }
                },
                {
                    "value": 3253,
                    "label": "Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#3A7493",
                        "size": 7
                    }
                },
                {
                    "value": 3254,
                    "label": "Pharmaceutical and Medicine Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#02A79E",
                        "size": 7
                    }
                },
                {
                    "value": 3255,
                    "label": "Paint, Coating, and Adhesive Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#F7AD4B",
                        "size": 7
                    }
                },
                {
                    "value": 3256,
                    "label": "Soap, Cleaning Compound, and Toilet Preparation Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#7BABBE",
                        "size": 7
                    }
                },
                {
                    "value": 3259,
                    "label": "Other Chemical Product and Preparation Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#96D8CB",
                        "size": 7
                    }
                },
                {
                    "value": 3261,
                    "label": "Plastics Product Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#FFCE6B",
                        "size": 7
                    }
                },
                {
                    "value": 3262,
                    "label": "Rubber Product Manufacturing",
                    "symbol": {
                        "type": "simple-marker",
                        "color": "#A9CCDA",
                        "size": 7
                    }
                }
            ]
        }
    });
};
