<template>
    <div id="app">
        <b-form inline>
            <H4 class="text-left state-form-text">Petrochemicals in Key Regions</H4>
            <b-form-select v-if="$store.state.mounted" v-model="selected_state" :options="state_tract_options" />
        </b-form>
        <br />

        <keep-alive v-if="$store.state.mounted">
            <Map />
        </keep-alive>
    
        <div v-if="!$store.state.mounted">
            <b-spinner />
        </div>
    </div>
</template>
  
<script>
import Map from "./components/Map";

export default {
    name: "app",
    data() {
        return {
            // map choice
            state_tract_options: [
                { text: "Louisiana", value: "Louisiana" },
                { text: "Ohio", value: "Ohio" },
                { text: "Pennsylvania", value: "Pennsylvania" },
                { text: "Texas", value: "Texas" },
                { text: "West Virginia", value: "West Virginia" },
            ],
        };
    },
    components: {
        Map,
    },
    computed: {
        selected_state: {
            get() {
                return this.$store.state.selected_state;
            },
            set(value) {
                this.$store.commit("update_state_map_index", value);
            },
        },
    },
    async mounted() {
        this.$store.dispatch("load_data");
    },
};
</script>
  
<style>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    width: 95%;
    height: 95%;
}

.state-form-text {
    margin-right: 10px;
    margin-bottom: 0;
    align-items: center;
}

</style>
  