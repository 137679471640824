import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        mounted: false,
        selected_state: undefined,

        data: {
            // all of these are are split into states and use the same index
            all_tracts: [],
            all_tract_data: [],
            all_emissions_data: [],
            all_unique_facilities: [],

            // cache for maximum population for each data set
            population_max: {}
        },
    },
    actions: {
        async load_data({ commit }) {
            const all_states = ["Louisiana", "Ohio", "Pennsylvania", "Texas", "West Virginia"];

            const Buffer_Analysis = (await import("./assets/data/buffer_analysis.json")).default;

            let all_tracts = {};
            let all_unique_facilities = {};
            let all_emissions_data = {};
            let eji_max_population = {};
            let max_emissions = {};

            await Promise.all(all_states.map(async (state) => {
                all_tracts[state] = (await import(`./assets/data/${state}/CDC_EJI_tracts.json`)).default;
                all_unique_facilities[state] = (await import(`./assets/data/${state}/unique_facilities.json`)).default

                // max population for eji rounded up to the closest thousand
                const population = all_tracts[state].features.map((tract) => tract.properties.e_totpp);
                eji_max_population[state] = Math.ceil(Math.max(...population) / 1000) * 1000;


                all_emissions_data[state] = {
                    "CAP": (await import(`./assets/data/${state}/CAP.json`)).default,
                    "HAP": (await import(`./assets/data/${state}/HAP.json`)).default,
                    "GHG": (await import(`./assets/data/${state}/GHG.json`)).default,
                };

                max_emissions[state] = {};
                for (const [pollutant_type, years] of Object.entries(all_emissions_data[state])) {
                    max_emissions[state][pollutant_type] = {};
                    for (const [year, data] of Object.entries(years)) {
                        max_emissions[state][pollutant_type][year] = Math.max(...data.map(point => point.attributes.emissions));
                    }
                }
            }));

            let all_buffer_analysis_data = {};
            let buffer_analysis_maximum_population = {}

            // split buffer analysis data per radius, then per state
            for (const [state, tract] of Object.entries(all_tracts)) {
                const state_abbr = tract.features[0].properties.stt_bbr;
                all_buffer_analysis_data[state] = {};
                buffer_analysis_maximum_population[state] = {};

                ["1 miles", "3 miles"].forEach((radius) => {
                    all_buffer_analysis_data[state][radius] =
                        Buffer_Analysis.filter((f) => f.attributes.state == state_abbr && f.attributes.buffer_radius == radius);

                    // max population for buffer_analysis rounded up to the closest thousand
                    const population = all_buffer_analysis_data[state][radius].map((data) => data.attributes.totpop_2020census);
                    buffer_analysis_maximum_population[state][radius] = Math.ceil(Math.max(...population) / 1000) * 1000;
                });
            }


            // all this data is split by state
            commit('set_data', {
                all_tracts: all_tracts,
                all_emissions_data: all_emissions_data,
                all_buffer_analysis_data: all_buffer_analysis_data,
                all_unique_facilities: all_unique_facilities,

                population_max: {
                    buffer_analysis: buffer_analysis_maximum_population,
                    eji: eji_max_population,
                },
                max_emissions: max_emissions
            });
        }
    },

    getters: {
        chosen_emission: state => (emission) => {
            return state.data.all_emissions_data[state.selected_state][emission];
        },
        chosen_unique_facilities: state => {
            return state.data.all_unique_facilities[state.selected_state];
        },
        chosen_state: state => {
            return state.data.all_tracts[state.selected_state].features[0].properties.stt_dsc;
        },
        chosen_tract_map: state => {
            return state.data.all_tracts[state.selected_state];
        },
        chosen_CDC_EJI_data_population_max: state => {
            return state.data.population_max.eji[state.selected_state];
        },
        chosen_BUFFER_ANALYSIS_data_population_max: state => (radius) => {
            return state.data.population_max.buffer_analysis[state.selected_state][radius];
        },
        chosen_max_emissions: state => {
            return state.data.max_emissions[state.selected_state];
        },
        chosen_BUFFER_ANALYSIS_data: (state) => (radius) => {
            // filter out any Buffer Analysis data point that doesn't match all filter ranges
            return state.data.all_buffer_analysis_data[state.selected_state][radius];
        },
    },
    mutations: {
        update_state_map_index(state, value) {
            state.selected_state = value;
        },
        set_data(state, data) {
            state.data = data;
            state.selected_state = "Louisiana",
            state.mounted = true;
        }
    }
});